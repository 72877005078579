<template>
<div>
    <v-app>
        <v-dialog :value="wire" width="400px" height="300px">

            <v-toolbar dense class="elevation-0 gradient-bg white--text">
                <div style="margin:auto;font-style:initial;">
                    DO YOU WANT TO LOGOUT..?
                </div>
            </v-toolbar>
            <v-card rounded dense class="overflow-hidden" height="100px" width="400px">
                <v-card-title>
                    <v-row class="justify-end"> </v-row>
                </v-card-title>

                <div class="mt-2">
                    <v-btn color="primary" dark dense class="ml-16 pt-n4 white--text" @click="close_dialog()">cancel</v-btn>


                    
                    <v-btn color="primary" text-small dense class="ml-16 white--text" @click="logout()">logout</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-app>
</div>
</template>

      
<script>
import {
    Auth
} from "aws-amplify";
export default {
    data: () => ({
        dialog: false,
    }),
    props: {
        wire: Boolean,
    },
    methods: {
        logout() {
            Auth.signOut();
            this.$router.push("/");
            this.$store.commit("SET_CURRENT_ROUTE", {});
        },

        close_dialog() {
            this.$emit("close");
        },
    },
};
</script>
