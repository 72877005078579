<template>
  <div>
    <v-dialog v-model="commentsOrgDailog" persistent width="500px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title> Organisation Comments</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
          class="mt-4"
        >
          <v-form ref="form">
            <v-textarea
              dense
              outlined
              rows="3"
              :counter="1000"
              label="Comments"
              v-model="Description"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  (v && v.length <= 1000) ||
                  'Description must be less than 1000 characters',
              ]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="validatecomments()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
        <script>
import { API, graphqlOperation } from "aws-amplify";
import { list_add_comments } from "@/graphql/mutations.js";
export default {
  components: {},
  props: {
    commentsOrgDailog: Boolean,
    getObject: Object,
  },
  data: () => ({
    Description: "",
  }),
  watch: {
    commentsOrgDailog: {
      handler() {
        {
          // console.log(this.getObject);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    validatecomments() {
      if (this.$refs.form.validate()) {
        this.delete_freeforms();
      }
    },
    async delete_freeforms() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_add_comments, {
            input: {
              action_type: "ADD",
              organisation_id: this.getObject.organisation_id,
              comment_message: this.Description,
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.list_add_comments);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>