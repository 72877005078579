var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mt-1",attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":"","right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"mr-5 mt-4"},on),[_c('h3',[_vm._v("Prospects")])])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v(" Strong prospect to cross 1000 credits and become our customer ")])]),_c('span',{staticClass:"ml-n2 mt-4 font-weight-bold",staticStyle:{"font-size":"15px","color":"#424242"}},[_vm._v(" "+_vm._s(_vm.creditslist)+" ")]),_c('v-spacer'),_c('v-select',{staticClass:"mt-8 mr-2",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.filterItems,"dense":"","outlined":"","label":"Filter By Referral ID","item-text":"referred_by","item-value":"referred_by"},model:{value:(_vm.filtered_ref_id),callback:function ($$v) {_vm.filtered_ref_id=$$v},expression:"filtered_ref_id"}}),_c('v-text-field',{staticClass:"mr-2 mt-8",staticStyle:{"max-width":"250px"},attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"gradient-bg white--text mt-4",attrs:{"small":""},on:{"click":function($event){return _vm.exporttoexcel()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""}},[_vm._v("mdi-export")]),_vm._v("Export ")],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.organisationData,"items":_vm.organisationItems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"items-per-page":100,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Organisations are present in this threshold ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.organisation_country",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.organisation_country)),_c('br'),_vm._v(_vm._s(item.organisation_state == undefined ? "N/A" : item.organisation_state))])]}},{key:"item.organisation_gst_number",fn:function(ref){
var item = ref.item;
return [(
              item.organisation_gst_number == '' ||
              item.organisation_gst_number == null ||
              item.organisation_gst_number == undefined
            )?_c('div',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-alpha-x-circle")])],1):_c('div',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle")])],1)]}},{key:"item.organisation_created_on",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.organisation_created_on))+" "),_c('br'),_vm._v(" "+_vm._s(item.onboarding_type == undefined ? "-" : "("+ item.onboarding_type +")"))])]}},{key:"item.organisation_name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow blue--text",on:{"click":function($event){return _vm.checkItem(item)}}},on),[_c('u',[_vm._v(" "+_vm._s(item.organisation_name.length >= 25 ? item.organisation_name.substr(0, 25) + "..." : item.organisation_name)+" ")])])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.organisation_name))])])]}},{key:"item.user_email_id",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.user_email_id)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.user_email_id))])])]}},{key:"item.last_swiped_on",fn:function(ref){
            var item = ref.item;
return [(item.last_swiped_on == undefined)?_c('span',{staticClass:"ml-5"},[_vm._v("-")]):_c('span',[_vm._v(_vm._s(_vm.get_date(item.last_swiped_on))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.get_time(item.last_swiped_on)))])]}},{key:"item.referred_by",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.referred_by != undefined && item.referred_by != null && item.referred_by.trim() != "" ? item.referred_by : "-")+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.referred_by))])])]}},{key:"item.Actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.opencomments(item)}}},on),[_vm._v("mdi-comment-account-outline")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Comments")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.opendeactive(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Deactivate Customer")])]),_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:( _vm.$store.getters.get_useremail == 'neeraj@mobil80.com'),expression:" $store.getters.get_useremail == 'neeraj@mobil80.com'"}],staticClass:"grey",attrs:{"disabled":_vm.$store.getters.get_useremail != 'neeraj@mobil80.com',"x-small":""},on:{"click":function($event){return _vm.delete_member_details(item)}}},on),[_c('v-icon',{staticClass:"black--text",attrs:{"small":""}},[_vm._v("mdi-incognito")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Impersonate ?")])])],1)]}},{key:"item.organisation_total_credits",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mr-5"},[_vm._v(_vm._s(item.organisation_total_credits))])]}},{key:"item.totalconsumed",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_consumed_credits))])]}},{key:"item.organisation_total_locations",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mr-4",domProps:{"textContent":_vm._s(
              item.organisation_total_locations < 10
                ? '0' + item.organisation_total_locations
                : item.organisation_total_locations
            )}}),_c('span',{staticClass:"mr-4",domProps:{"textContent":_vm._s(
              item.organisation_total_admin < 10
                ? '0' + item.organisation_total_admin
                : item.organisation_total_admin
            )}}),_c('span',{domProps:{"textContent":_vm._s(
              item.organisation_total_users < 10
                ? '0' + item.organisation_total_users
                : item.organisation_total_users
            )}})]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('ViewOrganisationDialog',{attrs:{"from_change_ref":_vm.from_change_ref,"viewOrgDialog":_vm.viewOrgDialog,"OrgObject":_vm.OrgObject,"AllOrgObject":_vm.sorted_organisationItems},on:{"close_view_org_dialog":function($event){_vm.viewOrgDialog = false},"get_refreshed_orgs":_vm.refresh_org_open_again,"get_refreshed_orgs2":_vm.refresh_org_open_again2}}),_c('DeleteDialog',{attrs:{"confDeleteDialog":_vm.confDeleteDialog,"deleteText":_vm.deleteText,"deleteIcon":_vm.deleteIcon,"deleteColor":_vm.deleteColor,"deleteLoading":_vm.deleteLoading},on:{"close_conf_delete_dialog":function($event){_vm.confDeleteDialog = false},"success_conf_delete":_vm.deleteSuccessAction}}),_c('OrgDeactive',{attrs:{"Orgdeletion":_vm.Orgdeletion,"getObject":_vm.getObject},on:{"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"clicked":function($event){_vm.Orgdeletion = false}}}),_c('OrgComments',{attrs:{"commentsOrgDailog":_vm.commentsOrgDailog,"getObject":_vm.getObject},on:{"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"clicked":function($event){_vm.commentsOrgDailog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }